
import { defineComponent } from 'vue';
import HistoryTimeLine from "@/components/HistoryTimeLine.vue";

export default defineComponent({
  components: {
    HistoryTimeLine
  },
  data() {
    return {
      dialog_email: false,
      dialog_btc: false,
      history: [
        {
          year: '2013~2015',
          title: 'Robotics Contest at JHS Club Activities',
          content: 'Participated in some robotics contests as a club activity. Our team won a special award in a area tournament.',
          env: 'C, C#, Hardware'
        },
        {
          year: '2015~2018',
          title: 'Teaching Assistant in SHS',
          content: 'Teaching Assistant in C language and JavaScript classes.',
          env: 'C, JavaScript'
        },
        {
          year: '2018~2022',
          title: 'Computer Science Student in a College',
          content: 'Learned programming and computer science in a college. Joined a programming club and some hackathon. My research&production work won a special incentive award on campus.',
          env: 'C#, Java, PHP, Python, R, WPF, Servlet, JSP, Spring'
        },
        {
          year: '2019~2021',
          title: 'Part-time backend and frontend developer in a Web company',
          content: 'Joined a web company and some project. Developed some hybrid apps, web apps and sites.',
          env: 'PHP, JavaScript, Cordova, Bash, Python'
        },
        {
          year: '2022~',
          title: 'Full-time mobile app developer in a Web company',
          content: 'Joined a web company and some project. Developed some apps.',
          env: 'Java, Kotlin, JavaScript, Bash'
        }
      ],
    }
  }
});
