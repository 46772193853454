<template>
  <v-container>
    <v-card class="mx-auto" elevation="4" max-width="500" title="Friends Links">
      <v-list lines="two">
        <v-list-item prepend-avatar="https://avatars.githubusercontent.com/u/12298476" title="justforlxz" href="https://blog.justforlxz.com"/>
        <v-list-item prepend-avatar="https://avatars.githubusercontent.com/u/13084946" title="hwdef" href="http://www.hwdef.org/"/>
        <v-list-item prepend-avatar="https://avatars.githubusercontent.com/u/62464927" title="LapisApple" href="https://laple.me/"/>
        <v-list-item prepend-avatar="https://avatars.githubusercontent.com/u/19504193" title="AzureZeng" href="https://azurezeng.com/"/>
        <v-list-item prepend-avatar="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7WYVq2Nb9fDK1hr0ch8w2--iR1WVVoxYdfK-Rm34q90sIR3fc" title="joexyz" href="https://joexyz.online/"/>
        <v-list-item prepend-avatar="https://avatars.githubusercontent.com/u/2550010" title="hdk5" href="https://hdk5.xyz/"/>
        <v-list-item prepend-avatar="https://avatars.githubusercontent.com/u/76040821" title="bagussona" href="http://bagussona.github.io/"/>
        <v-list-item prepend-avatar="https://avatars.githubusercontent.com/u/64476715" title="jaycedotbin" href="http://jaycedotbin.github.io/"/>
      </v-list>
    </v-card>
  </v-container>
</template>

<style>
  .mx-auto {
    margin: 10px auto;
  }
</style>
