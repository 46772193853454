import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/img/btc.png'


export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_avatar = _resolveComponent("v-avatar")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_HistoryTimeLine = _resolveComponent("HistoryTimeLine")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_layout = _resolveComponent("v-layout")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, {
        class: "mx-auto",
        elevation: "4",
        "max-width": "500"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, { class: "text-h5" }, {
            default: _withCtx(() => [
              _createTextVNode("yuk7")
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_text, { class: "py-0" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, {
                align: "center",
                "hide-gutters": "",
                "no-gutters": ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: "9" }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, [
                        _createVNode(_component_v_icon, null, {
                          default: _withCtx(() => [
                            _createTextVNode("mdi mdi-cake-variant")
                          ]),
                          _: 1
                        }),
                        _createTextVNode(" Jan. 2000 ")
                      ]),
                      _createElementVNode("p", null, [
                        _createVNode(_component_v_icon, null, {
                          default: _withCtx(() => [
                            _createTextVNode("mdi mdi-city")
                          ]),
                          _: 1
                        }),
                        _createTextVNode(" Tokyo, Japan ")
                      ]),
                      _createElementVNode("p", null, [
                        _createVNode(_component_v_icon, null, {
                          default: _withCtx(() => [
                            _createTextVNode("mdi mdi-heart")
                          ]),
                          _: 1
                        }),
                        _createTextVNode(" Linux/Android Udon/Chocolate/Mint ")
                      ]),
                      _createElementVNode("p", null, [
                        _createVNode(_component_v_icon, null, {
                          default: _withCtx(() => [
                            _createTextVNode("mdi mdi-code-braces")
                          ]),
                          _: 1
                        }),
                        _createTextVNode(" C#/Go/Java/Kotlin/PHP/JavaScript ")
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { class: "text-right" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_avatar, {
                        class: "profile",
                        color: "grey",
                        size: "60%"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_img, {
                            src: "https://avatars2.githubusercontent.com/u/29954265",
                            "max-width": "200px"
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_spacer),
          _createVNode(_component_v_card_actions, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                icon: "",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dialog_email = true))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, null, {
                    default: _withCtx(() => [
                      _createTextVNode("fas fa-envelope")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_btn, {
                icon: "",
                href: "https://t.me/yuk_7"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, null, {
                    default: _withCtx(() => [
                      _createTextVNode("fab fa-telegram")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_btn, {
                icon: "",
                href: "https://keybase.io/yuk7"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, null, {
                    default: _withCtx(() => [
                      _createTextVNode("fab fa-keybase")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_btn, {
                icon: "",
                href: "https://github.com/yuk7"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, null, {
                    default: _withCtx(() => [
                      _createTextVNode("fab fa-github")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_btn, {
                icon: "",
                href: "https://paypal.me/yuk77"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, null, {
                    default: _withCtx(() => [
                      _createTextVNode("fab fa-paypal")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_btn, {
                icon: "",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.dialog_btc = true))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, null, {
                    default: _withCtx(() => [
                      _createTextVNode("fab fa-bitcoin")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_card, {
        class: "mx-auto",
        elevation: "4",
        "max-width": "500",
        title: "Skills"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_list, { lines: "two" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_list_item, {
                prependIcon: "mdi mdi-linux",
                title: "Linux",
                subtitle: "Kernel build, User side, Server settings"
              }),
              _createVNode(_component_v_list_item, {
                prependIcon: "mdi mdi-package-variant",
                title: "Virtualization + Container Techs",
                subtitle: "Docker, LXC, WSL"
              }),
              _createVNode(_component_v_list_item, {
                prependIcon: "mdi mdi-android",
                title: "Android + Mobile App Development",
                subtitle: "Java, Kotlin, KMP, Cordova"
              }),
              _createVNode(_component_v_list_item, {
                prependIcon: "mdi mdi-web",
                title: "Web Development",
                subtitle: "JavaScript, PHP, Java, Go"
              }),
              _createVNode(_component_v_list_item, {
                prependIcon: "mdi mdi-dot-net",
                title: ".NET Development",
                subtitle: "C#, VB.NET, WinForms, WPF"
              }),
              _createVNode(_component_v_list_item, {
                prependIcon: "mdi mdi-server",
                title: "IT Operations",
                subtitle: "Computer and Server Operations"
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_card, {
        class: "mx-auto",
        elevation: "4",
        "max-width": "500",
        title: "Life Experience"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_HistoryTimeLine, { items: _ctx.history }, null, 8, ["items"])
        ]),
        _: 1
      }),
      _createVNode(_component_v_dialog, {
        modelValue: _ctx.dialog_email,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.dialog_email) = $event))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, { title: "Email" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _createTextVNode(" yukx00@gmail.com ")
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_actions, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_spacer),
                  _createVNode(_component_v_btn, { href: "mailto:yukx00@gmail.com" }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Open in App ")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_btn, {
                    text: "",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.dialog_email = false))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Close ")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_v_dialog, {
        modelValue: _ctx.dialog_btc,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.dialog_btc) = $event))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, { title: "Bitcoin" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _createTextVNode(" 1GepqF7fxX1Z2jJsF2tQQNGAvoVYx6syDK ")
                ]),
                _: 1
              }),
              _createVNode(_component_v_layout, { "justify-center": "" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_img, {
                    src: _imports_0,
                    "max-width": "200px"
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_actions, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_spacer),
                  _createVNode(_component_v_btn, { href: "bitcoin:1GepqF7fxX1Z2jJsF2tQQNGAvoVYx6syDK" }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Open in App ")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_btn, {
                    text: "",
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.dialog_btc = false))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Close ")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }))
}