import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-h5" }
const _hoisted_2 = { class: "text-body-1" }
const _hoisted_3 = { style: {"color":"gray"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_timeline_item = _resolveComponent("v-timeline-item")!
  const _component_v_timeline = _resolveComponent("v-timeline")!

  return (_openBlock(), _createBlock(_component_v_timeline, { density: "comfotable" }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, i) => {
        return (_openBlock(), _createBlock(_component_v_timeline_item, {
          key: i,
          size: "8",
          dotColor: "black",
          "fill-dot": ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_container, { class: "fill-height" }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, _toDisplayString(item.year), 1),
                _createElementVNode("div", null, [
                  _createElementVNode("div", _hoisted_1, _toDisplayString(item.title), 1),
                  _createElementVNode("div", _hoisted_2, _toDisplayString(item.content), 1),
                  _createElementVNode("p", _hoisted_3, [
                    _createElementVNode("small", null, _toDisplayString(item.env), 1)
                  ])
                ])
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }))
}