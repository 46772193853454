
  import { defineComponent } from 'vue';
  import ParticleSnowBackground from "@/components/ParticleSnowBackground.vue";

  export type DataType = {
    theme: string
  }

  export default defineComponent({
    name: 'App',
    components: {
      ParticleSnowBackground
    },
    data(): DataType {
      return {
        theme: "light"
      }
    },
    methods: {
      toggleTheme() {
        this.theme = this.theme === "light" ? "dark" : "light";
      }
    },
    created() {
      const darkMedia = window.matchMedia('(prefers-color-scheme: dark)')
      if (darkMedia.matches == true) {
        this.theme = "dark"
      }
      darkMedia.addEventListener("change", event => {
        if (event.matches) {
          this.theme = "dark"
        } else {
          this.theme = "light"
        }
      })
    }
  })
